var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("date-picker", {
    attrs: {
      placeholder: _vm.placeholder,
      type: _vm.option.typeOfDate,
      format: _vm.option.format,
      "value-type": _vm.option.returnValueFollowFormat,
      "disabled-date": _vm.disabledDate,
    },
    on: {
      change: function ($event) {
        return _vm.changeDate()
      },
    },
    model: {
      value: _vm.thisDate,
      callback: function ($$v) {
        _vm.thisDate = $$v
      },
      expression: "thisDate",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }