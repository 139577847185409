var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("b-modal", {
    ref: _vm.modalId,
    staticClass: "normal-modal",
    attrs: {
      id: _vm.modalId,
      size: _vm.size,
      centered: "",
      title: _vm.$t("requireConsultantTime"),
      "no-close-on-backdrop": "",
    },
    on: {
      ok: function ($event) {
        return _vm.ok()
      },
      close: _vm.close,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "modal-header",
          fn: function (_ref) {
            var close = _ref.close
            return [
              _c("img", {
                staticClass: "normal-close-btn",
                attrs: {
                  src: _vm.closeIconUrl,
                },
                on: {
                  click: function ($event) {
                    return close()
                  },
                },
              }),
            ]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "h2",
                {
                  staticClass: "normal-modal-title",
                },
                [_vm._v(_vm._s(_vm.modalTitle))]
              ),
              _vm._t("modal-body"),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function (_ref2) {
            var hide = _ref2.hide
            return [
              _c(
                "b-button",
                {
                  staticClass: "normal-submit-btn",
                  attrs: {
                    variant: "success",
                    size: "sm",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.ok()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: ["fa", "check"],
                      size: "2x",
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }