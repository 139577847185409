var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "skeleton",
        {
          attrs: {
            loading: _vm.loading,
          },
        },
        [
          _c(
            "template",
            {
              slot: "skeleton-template",
            },
            [_c("skeleton-template")],
            1
          ),
          _c(
            "template",
            {
              slot: "origin-template",
            },
            [
              _c("server-time-clock"),
              _c(
                "div",
                {
                  staticClass: "wuwow-card",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "wuwow-card-head",
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "wuwow-card-title",
                        },
                        [_vm._v(_vm._s(_vm.$t("consultantSchedule")))]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "wuwow-card-body consultant-calendar-body",
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                cols: "12",
                                md: "3",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img-consultant-pictrue",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.consultantInfo.picture,
                                      alt: _vm.consultantInfo.name,
                                      title: _vm.consultantInfo.name,
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "title-text",
                              attrs: {
                                cols: "12",
                                md: "9",
                              },
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "mb-4",
                                },
                                [_vm._v(_vm._s(_vm.consultantInfo.name))]
                              ),
                              _c(
                                "b-alert",
                                {
                                  staticClass: "text-center",
                                  attrs: {
                                    variant: "warning",
                                    show: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "點擊 + 進行顧問未開放時段之申請，需經顧問同意後始完成訂課"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("full-calendar", {
                        ref: "vue-calendar",
                        attrs: {
                          options: _vm.calendarOptions,
                        },
                      }),
                      _c(
                        "b-container",
                        {
                          staticClass: "bv-example-row",
                        },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                "align-h": "end",
                              },
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-center",
                                  attrs: {
                                    cols: "6",
                                    md: "9",
                                    lg: "10",
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-outline-primary btn-consultant-back",
                                      attrs: {
                                        type: "button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.back()
                                        },
                                      },
                                    },
                                    [_vm._v("返 回")]
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName:
                                        "v-b-modal.requireConsultantTimeModal",
                                      modifiers: {
                                        requireConsultantTimeModal: true,
                                      },
                                    },
                                  ],
                                  staticClass: "float-right",
                                  attrs: {
                                    cols: "6",
                                    md: "3",
                                    lg: "2",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "calendar-plus-icon mr-2",
                                    attrs: {
                                      icon: "calendar-plus",
                                      size: "2x",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "medium-text",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("requireConsultantTime"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("require-consultant-time-modal", {
        attrs: {
          consultantInfo: {
            name: _vm.consultantInfo.name,
            id: _vm.hbConsultantId,
          },
          customizedClassroomRule: _vm.customizedClassroomRule,
        },
      }),
      _c("book-consultant-time-modal", {
        attrs: {
          bookingTime: _vm.bookTimes[0],
          consultantInfo: {
            name: _vm.consultantInfo.name,
            id: _vm.hbConsultantId,
          },
          newRule: _vm.newRule,
          customizedClassroomRule: _vm.customizedClassroomRule,
        },
        on: {
          applySuccess: _vm.applySuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "monthModal",
            title: "訊息",
            "ok-title": "確定",
            "cancel-title": "取消",
            centered: "",
            "header-bg-variant": "primary",
          },
          on: {
            ok: function ($event) {
              return _vm.switchMonth()
            },
          },
          model: {
            value: _vm.monthModal,
            callback: function ($$v) {
              _vm.monthModal = $$v
            },
            expression: "monthModal",
          },
        },
        [
          _c(
            "h4",
            {
              staticClass: "text-center",
            },
            [
              _vm._v("是否跳至"),
              _c(
                "span",
                {
                  staticClass: "text-danger",
                },
                [_vm._v(_vm._s(_vm.formatMonth(_vm.toMonth)))]
              ),
              _vm._v("月進行訂課"),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }